<template>
  <div v-if="editable" class="d-flex align-center">
    <v-text-field
      v-model="amount"
      :error-messages="amountError"
      solo
      flat
      dense
      outlined
      hide-details
      type="number"
      style="width: 100%;"
      placeholder="금액 입력"
      label="금액 입력"
      @input="v$.amount.$touch()"
      @blur="v$.amount.$touch()"
    />
    <v-select
      v-model="currency"
      :items="currencyItems"
      :error-messages="currencyError"
      solo
      flat
      dense
      outlined
      hide-details
      placeholder="단위"
      @input="v$.currency.$touch()"
      @blur="v$.currency.$touch()"
    />
  </div>
  <span v-else>{{ `${amount}${currencySymbol}` }}</span>
</template>

<script>
import { decimal, requiredIf } from "@vuelidate/validators";
import useVuelidate from "@vuelidate/core";
import currency from "../../../../constant/currency";

export default {
  name: "AmountFieldItem",
  setup: () => ({ v$: useVuelidate() }),
  props: {
    value: {
      type: Object,
      description: "필드 데이타 (v-model)"
    },
    templateFrame: {
      type: Object,
      required: true,
      description: "필드 속성"
    },
    editable: {
      type: Boolean,
      default: false,
      description: "편집 모드"
    }
  },
  computed: {
    amount: {
      get() {
        return this.value?.amount ?? "";
      },
      set(val) {
        this.$emit("input", { ...this.value, amount: val });
      }
    },
    currency: {
      get() {
        return this.value?.currency ?? "";
      },
      set(val) {
        this.$emit("input", { ...this.value, currency: val });
      }
    },
    currencySymbol() {
      return currency?.[this.currency]?.symbol ?? "";
    },
    currencyItems() {
      return Object.values(currency);
    },
    amountError() {
      const { $dirty, required, decimal } = this.v$.amount;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      if (decimal.$invalid) errors.push("숫자만 입력 가능합니다.");
      return errors;
    },
    currencyError() {
      const { $dirty, required } = this.v$.currency;
      const errors = [];
      if (!$dirty) return errors;
      if (required.$invalid) errors.push("필수 입력사항 입니다.");
      return errors;
    }
  },
  methods: {
    async validate() {
      return await this.v$.$validate();
    }
  },
  validations() {
    return {
      amount: {
        required: requiredIf(this.templateFrame?.required),
        decimal
      },
      currency: {
        required: requiredIf(this.templateFrame?.required)
      }
    };
  },
  mounted() {
    this.$nextTick(() => {
      // 기본값 초기화 (수정일 경우 기존값 할당)
      this.amount = this.value?.amount ?? "";
      this.currency = this.value?.currency ?? currency.KRW.value;
    });
  }
};
</script>

<style lang="scss" scoped></style>
