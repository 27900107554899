export const currency = {
  KRW: { text: "원", symbol: "₩", value: "KRW" },
  USD: { text: "달러", symbol: "$", value: "USD" },
  EUR: { text: "유로", symbol: "€", value: "EUR" },
  JPY: { text: "옌(JPY)", symbol: "¥(JPY)", value: "JPY" },
  GBP: { text: "파운드", symbol: "£", value: "GBP" },
  CNY: { text: "위안", symbol: "¥(CNY)", value: "CNY" },
  RUB: { text: "루블", symbol: "р.", value: "RUB" },
  BRL: { text: "헤알", symbol: "R$", value: "BRL" },
  CAD: { text: "달러(CAD)", symbol: "$(CAD)", value: "CAD" }
};

export default currency;
